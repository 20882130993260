import React, { FunctionComponent } from "react";
import classNames from "classnames";
import loadable from "@loadable/component";

import { ImageInterface, PortableText } from "../../types/SanityTypes";
import useMediaQuery from "../../hooks/useMediaQuery";

import "./styles.scss";

const CaseStudy = loadable(() => import("../CaseStudy"));
const ImageTextBlock = loadable(() => import("../ImageTextBlock"));
const CardList = loadable(() => import("../CardList"));

type ThemeProps = {
  bgColor: {
    title: string;
    value: string;
  };
};

type SlideProps = {
  image: ImageInterface;
  content: PortableText;
  position: string;
  theme: ThemeProps;
};

interface VerticalSliderInterface {
  slides: Array<SlideProps>;
  theme: ThemeProps;
}

type SlideRenderableSanityTypeNameToComponentMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [typeName: string]: FunctionComponent<any>;
};

const slideMap: SlideRenderableSanityTypeNameToComponentMap = {
  SanityCaseStudyBlock: CaseStudy,
  SanityImageBlock: ImageTextBlock,
  SanityCardList: CardList
};

const VerticalSlider: FunctionComponent<VerticalSliderInterface> = ({ slides, theme }) => {
  const bgClassName = theme?.bgColor?.title || "default";
  const matches = useMediaQuery("(max-width: 1200px)");

  const getComponentHeight = (type: string): string => {
    const ComponentHeightMapping: { [key: string]: string } = {
      SanityCaseStudyBlock: "vertical-slider--case-study",
      SanityImageBlock: "vertical-slider--image-block",
      SanityCardList: "vertical-slider--advanced-card-list"
    };

    return ComponentHeightMapping[type];
  };

  const SlideRenderer: FunctionComponent<{ slide: any }> = ({ slide }) => {
    if (!slide) {
      return null;
    }
    const getComponent = (slideType: string) => {
      const component = slideMap[slideType];
      if (component) {
        return component;
      } else {
        console.error(`Unknown card type: ${slideType}`);
        return null;
      }
    };

    const Comp = getComponent(slide.__typename);

    return Comp ? React.createElement(Comp, { ...slide }) : null;
  };

  return (
    <div className={classNames("vertical-slider", `${bgClassName.toLowerCase()}--theme`)}>
      <div className={classNames({ "layout-container": !matches })}>
        {slides.map((slide: SlideProps, i: number) => (
          <div className={classNames("vertical-slider__slide", getComponentHeight(slide.__typename))} key={i}>
            <SlideRenderer slide={slide} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerticalSlider;
